import { LOANS as loans } from "../config/routes";
import apiClient from "../../lib/api_request/api_request_V2";

export const getLoans = async (params) => {
    return await
        apiClient.get(`${loans}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

export const addLoan = async (loan) => {
    return await
        apiClient.post(`${loans}`, loan)
            .then(resp => resp)
            .catch(err => err)
}

export const deleteLoan = async (id) => {
    return await
        apiClient.delete(`${loans}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}

export const updateLoan = async (loan, id) => {
    return await
        apiClient.patch(`${loans}/${id}`, loan)
            .then(resp => resp)
            .catch(err => err)
}

// question: what is behind id? Is it loanId = <uuid>?
export const writeOffLoan = async (id, filial) => {
    return await
        apiClient.post(`${loans}/writeoff/${id}?filial=${filial}`)
            .then(resp => resp)
            .catch(err => err)
}