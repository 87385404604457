import { CASHFLOWS as cashflows } from "../config/routes";
import apiClient from "../../lib/api_request/api_request_V2";

export const getCashflow = async (params) => {
    return await
        apiClient.get(`${cashflows}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

export const addCashflow = async (cashflow) => {
    return await
        apiClient.post(`${cashflows}`, cashflow)
            .then(resp => resp.data)
            .catch(err => err)
}

export const deleteCashflow = async (id) => {
    return await
        apiClient.delete(`${cashflows}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}