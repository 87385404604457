import { MEDIA as media } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";
import { parseApiError } from "./api_errorhandler";
import apiClient from "../../lib/api_request/api_request_V2";

// keep old implementation until documents moved over to new implementation
const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${media}`;
const accessToken = await getCookie(NOVACOOKIE);

export const getMedia = async (params) => {
    return await
        apiClient.get(`${media}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

export const createMedia = async (params, media) => {
    const formData = new FormData();
    if (params === "documents") {
        formData.append("document", media.media);
        formData.append("otherinfo", media.otherinfo);
    } else {
        formData.append("image", media.media);
        formData.append("otherinfo", media.otherinfo);
    }

    // temporary code to phase out original image workflow
    // ToDo: remove v1 in backend after feature is stable across all uses
    if (params === "images") {
        params = `v2/${params}`;
    }

    return await
        sendRequest.request(
            `${endpoint}/${params}`,
            POST,
            formData,
            accessToken,
            "multipart/form.data"
        )
            .then(resp => resp.data)
            .catch(err => err)
}

// export const createMedia = async (params, media) => {
//     const formData = new FormData();
//     if (params === "documents") {
//         formData.append("document", media.media);
//         formData.append("otherinfo", media.otherinfo);
//     } else {
//         formData.append("image", media.media);
//         formData.append("otherinfo", media.otherinfo);
//     }

//     // temporary code to phase out original image workflow
//     // ToDo: remove v1 in backend after feature is stable across all uses
//     if (params === "images") {
//         params = `v2/${params}`;
//     }

//     return await
//         apiClient.post(`${media}/${params}`, formData, { headers: { 'Content-Type': 'multipart/form.data' } })
//             .then(resp => resp.data)
//             .catch(err => err)
// }

// commented out on 12.12 - remove when new method is stable
// export const deleteMedia = async (params) => {
//     return await
//         sendRequest.request(
//             `${endpoint}?${params}`,
//             DELETE,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => ({ "code": 401, "text": err }))
// }

export const deleteMedia = async (params) => {
    return await
        apiClient.delete(`${media}?${params}`)
            .then(resp => resp)
            .catch(err => err)
}